import { CLEAR_MANIFEST_SERVICE_APPLETS, SET_MANIFEST_SERVICE_APPLETS } from '../actions/manifest';

const initialState = {
  serviceApplets: {
    data: [],
    isLoading: false,
    error: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MANIFEST_SERVICE_APPLETS: {
      return { ...state, serviceApplets: { ...state.serviceApplets, ...action.data } };
    }
    case CLEAR_MANIFEST_SERVICE_APPLETS: {
      return { ...state, serviceApplets: initialState };
    }
    default:
      return state;
  }
};
