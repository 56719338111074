export const SET_MANIFEST_SERVICE_APPLETS = 'manifest/SET_MANIFEST_SERVICE_APPLETS';
export const CLEAR_MANIFEST_SERVICE_APPLETS = 'manifest/CLEAR_MANIFEST_SERVICE_APPLETS';

export const setManifestServiceApplets = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_MANIFEST_SERVICE_APPLETS, data: value });
  };
};

export const clearManifestServiceApplets = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_MANIFEST_SERVICE_APPLETS });
  };
};
