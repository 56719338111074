export const LODGEMENTSTATUS = {
  LODGED: 'Lodged',
  SCAN: 'Scan Only',
  DROP: 'Drop Only'
};

export const LODGEMENTSTATUS_API = {
  lodged: LODGEMENTSTATUS.LODGED,
  scan_only: LODGEMENTSTATUS.SCAN,
  drop_only: LODGEMENTSTATUS.DROP
};

export const swapStatus = (json) => {
  let ret = {};
  for(let key in json){
    ret[json[key]] = key;
  }
  return ret;
}

export const UPDATE_MANIFESTFILTER = 'manifest/UPDATEMANIFESTFILTER';

export const updateManifestFilter = (filters) => {
  return (dispatch, getState) => {
    dispatch({type: UPDATE_MANIFESTFILTER, retainedMSessionsFilters: filters});
  }
}

export const UPDATE_MANIFESTJUMPSESSIONEXPANDED = 'manifest/MANIFESTJUMPSESSIONEXPANDED';

export const updateManifestJumpSessionExpanded = (expanded) => {
  return (dispatch, getState) => {
    dispatch({type: UPDATE_MANIFESTJUMPSESSIONEXPANDED, sessionFilterExpanded: expanded});
  }
}

export const UPDATE_MANIFESTJUMPSESSIONFILTER = 'manifest/MANIFESTJUMPSESSIONFILTER';

export const updateManifestJumpSessionFilter = (filter) => {
  return (dispatch, getState) => {
    dispatch({type: UPDATE_MANIFESTJUMPSESSIONFILTER, jumpSessionFilter: filter});
  }
}

export const FILTER = {
  SAMEASSESSIONSTABLE: 'as seen in the main Session table (default)',
  SAMEHOURASTHISESSION: 'with the same hour as this session',
  SAMEDATEASTHISESSION: 'with the same date as this session',
  SAMEORDERID: 'with the same Order ID',
  SAMEUPC: 'with the same UPC',
  SAMEPRODUCTNUMBER: 'with the same Product Number',
  SAMEOVERALLSTATUS: 'with the same Overall Status',
}